// src/Map.js
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import './Map.css';
import ImagePopup from './ImagePopup';
import { useShowType } from '../context/ShowTypeContext';
import { useHighlightedGroup } from '../context/HighlightedGroupContext';
import { useAreaMeasurement } from '../context/AreaMeasurementContext'; // Import the Area Measurement context

// Import GeoJSON files
import femaleRowGroupings from '../points/female_row_groupings.json';

async function fetchGeoJSON(url) {
  const response = await fetch(url);
  if (!response.ok) {
      throw new Error(`Failed to load GeoJSON from ${url}`);
  }
  return response.json();
}

let geojsonDataAll = await fetchGeoJSON("https://pub-bca0943c0a014ebb9e7f55fb5dd1350e.r2.dev/points/all.geojson");
let geojsonDataMale = await fetchGeoJSON("https://pub-bca0943c0a014ebb9e7f55fb5dd1350e.r2.dev/points/only_male.geojson");
let geojsonDataFemale = await fetchGeoJSON("https://pub-bca0943c0a014ebb9e7f55fb5dd1350e.r2.dev/points/only_female.geojson");

async function fetchGeojsonData(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    const geojsonData = await response.json();
    return geojsonData;
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ styleURL }) => {
  const { showMale, showFemale } = useShowType();
  const { highlightedGroup } = useHighlightedGroup();
  const { isActive, tasselOption, unitOption, toggleActive } = useAreaMeasurement();
  const [imageName, setImageName] = useState();
  const mapContainer = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [map, setMap] = useState(null);
  const [clickedPoints, setClickedPoints] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [areaPolygon, setAreaPolygon] = useState([]); // Store area polygon points

  const [bearing, setBearing] = useState(0); // Store area polygon points


  const isActiveRef = useRef(isActive);
  const areaPolygonRef = useRef(areaPolygon); // Create a ref to hold the polygon coordinates
  const [area, setArea] = useState(0); // Store the calculated area value

  const calculateArea = () => {
    if (areaPolygon.length < 3) {
      setArea(0);
      return;
    }

    const polygon = turf.polygon([areaPolygon.concat([areaPolygon[0]])]); // Close the polygon
    let areaInSquareMeters = turf.area(polygon);

    // Convert the area based on the selected unit
    switch (unitOption) {
      case 'Square kilometer':
        areaInSquareMeters /= 1e6; // Convert from square meters to square kilometers
        break;
      case 'Hectare':
        areaInSquareMeters /= 1e4; // Convert from square meters to hectares
        break;
      default:
        break; // Default is square meters
    }
    setArea(areaInSquareMeters);
  };


  // Function to add the unclustered-point layer with dynamic opacity
  const addUnclusteredPointLayer = (map) => {
    if (!map) return;

    if (map.getLayer('unclustered-point')) {
      map.removeLayer('unclustered-point');
    }

    // Do not add the layer if area measurement is active and the option is to hide tassels
    console.log("showMale: ", showMale);
    console.log("showFemale: ", showFemale);
    if ((isActive && tasselOption === 'Don\'t show tassels') || (!showMale && !showFemale)) return;

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'tassels',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': [
          'case',
          ['==', ['get', 'row_type'], 'male'],
          '#0000FF',
          ['==', ['get', 'row_type'], 'female'],
          '#FF0000',
          ['==', ['get', 'row_type'], 'unlabeled'],
          '#ffe900',
          ['==', ['get', 'category'], 4],
          '#f0d6ee',
          '#000000',
        ],
        'circle-radius': [
          'case',
          ['==', isActive && tasselOption === 'Show blurred tassels', true], // Check if highlightedGroup is 0 (undefined)
          4,
          ['==', highlightedGroup, 0],
          5,
          ['==', ['get', 'group_id'], highlightedGroup],
          6,
          4
        ],
        'circle-stroke-width': [
          'case',
          ['==', isActive && tasselOption === 'Show blurred tassels', true], // Check if highlightedGroup is 0 (undefined)
          0,
          ['==', highlightedGroup, 0], // Check if highlightedGroup is 0 (undefined)
          1, // Fully opaque if highlightedGroup is 0
          ['==', ['get', 'group_id'], highlightedGroup], // Check if the group_id matches highlightedGroup
          1,  // Fully opaque if it matches
          0 // More transparent if it does not match
        ],
        'circle-stroke-color': '#fff',
        'circle-opacity': [
          'case',
          ['==', isActive && tasselOption === 'Show blurred tassels', true], // Check if highlightedGroup is 0 (undefined)
          0.4,
          ['==', highlightedGroup, 0], // Check if highlightedGroup is 0 (undefined)
          1, // Fully opaque if highlightedGroup is 0
          ['==', ['get', 'group_id'], highlightedGroup], // Check if the group_id matches highlightedGroup
          1,  // Fully opaque if it matches
          0.5 // More transparent if it does not match
        ],
      },
    });
  };

  // Function to add clustered points layer
  const addClusteredPointLayer = (map) => {
    if (!map) return;

    if (map.getLayer('clusters')) {
      map.removeLayer('clusters');
    }
    if (map.getLayer('cluster-count')) {
      map.removeLayer('cluster-count');
    }

    // Do not add the layers if area measurement is active and the option is to hide tassels
    if ((isActive && tasselOption === 'Don\'t show tassels') || (!showMale && !showFemale)) return;

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'tassels',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1',
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40,
        ],
      },
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'tassels',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });
  };

  useEffect(() => {
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/csicsi111/' + styleURL,
    });

    newMap.on('load', () => {
      newMap.addSource('tassels', {
        type: 'geojson',
        data: geojsonDataAll,
        cluster: true,
        clusterMaxZoom: 17,
        clusterRadius: 50,
      });

      addClusteredPointLayer(newMap);
      addUnclusteredPointLayer(newMap);

      newMap.addSource('clicked-points', {
        type: 'geojson',
        data: clickedPoints,
      });

      newMap.addLayer({
        id: 'clicked-points-layer',
        type: 'circle',
        source: 'clicked-points',
        paint: {
          'circle-radius': 5,
          'circle-color': '#FFFF00',
        },
      });

      // Handle click events for area measurement
      newMap.on('click', (e) => {
        if (isActiveRef.current) {
          const newFeature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [e.lngLat.lng, e.lngLat.lat],
            },
            properties: {},
          };


          setClickedPoints((prevState) => ({
            type: 'FeatureCollection',
            features: [...prevState.features, newFeature],
          }));

          if (isActiveRef.current) {
            const newPoint = [e.lngLat.lng, e.lngLat.lat];

            // Update both state and ref
            setAreaPolygon((prev) => {
              areaPolygonRef.current = [...prev, newPoint]; // Update the ref
              return [...prev, newPoint]; // Return the updated state
            });

            // Optional: Add clicked point logic
          }
        } else {
          const features = newMap.queryRenderedFeatures(e.point, {
            layers: ['unclustered-point'],
          });

          if (features.length > 0) {
            const feature = features[0];
            console.log("clicked image name: ", feature.properties.image_name);
            setImageName(feature.properties.image_name);
            setPopupOpen(true);
          }
        }
      });

      newMap.on('rotateend', () => {
        const finalRotation = newMap.getBearing();
        setBearing(finalRotation);
      });

      newMap.on('mouseenter', 'unclustered-point', function () {
        newMap.getCanvas().style.cursor = 'pointer';
      });

      newMap.on('mouseleave', 'unclustered-point', function () {
        newMap.getCanvas().style.cursor = '';
      });

      newMap.on('mousemove', (e) => {
        if (isActiveRef.current && areaPolygonRef.current.length > 0) {
          const currentCoordinates = [
            ...areaPolygonRef.current,
            [e.lngLat.lng, e.lngLat.lat],
          ];

          // If there's only one point in areaPolygonRef, draw a line
          if (areaPolygonRef.current.length === 1) {
            if (!newMap.getLayer('area-line')) {
              newMap.addLayer({
                id: 'area-line',
                type: 'line',
                source: {
                  type: 'geojson',
                  data: {
                    type: 'FeatureCollection',
                    features: [{
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: currentCoordinates,
                      },
                    }],
                  },
                },
                paint: {
                  'line-color': '#FF0', // Yellow color for the line
                  'line-width': 2,
                },
              });
            } else {
              const lineSource = newMap.getSource('area-line');
              lineSource.setData({
                type: 'FeatureCollection',
                features: [{
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: currentCoordinates,
                  },
                }],
              });
            }
          } else {
            // Remove the line layer if it exists when we have more than one point
            if (newMap.getLayer('area-line')) {
              newMap.removeLayer('area-line');
              newMap.removeSource('area-line');
            }

            // Draw the polygon if there are more than one point
            const closedCoordinates = [...currentCoordinates, areaPolygonRef.current[0]];

            if (!newMap.getLayer('area-polygon')) {
              newMap.addLayer({
                id: 'area-polygon',
                type: 'fill',
                source: {
                  type: 'geojson',
                  data: {
                    type: 'FeatureCollection',
                    features: [{
                      type: 'Feature',
                      geometry: {
                        type: 'Polygon',
                        coordinates: [closedCoordinates],
                      },
                    }],
                  },
                },
                paint: {
                  'fill-color': 'rgba(255, 255, 0, 0.5)', // Semi-transparent yellow
                },
              });
            } else {
              const polygonSource = newMap.getSource('area-polygon');
              polygonSource.setData({
                type: 'FeatureCollection',
                features: [{
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [closedCoordinates],
                  },
                }],
              });
            }
          }
        }
      });






      setMap(newMap);
    });

    return () => newMap.remove();
  }, [styleURL]);

  useEffect(() => {
    if (map) {
      let geojsonData;
      if (showMale && showFemale) {
        geojsonData = geojsonDataAll;
      } else if (showMale) {
        geojsonData = geojsonDataMale;
      } else if (showFemale) {
        geojsonData = geojsonDataFemale;
      } else {
        geojsonData = null;
      }

      if (geojsonData) {
        map.getSource('tassels').setData(geojsonData);
      }
      if (map) {
        addUnclusteredPointLayer(map);
        addClusteredPointLayer(map);
      }
    }
  }, [showMale, showFemale, map]);

  useEffect(() => {
    if (map) {
      const source = map.getSource('clicked-points');
      if (source) {
        source.setData(clickedPoints);
      }
    }
  }, [clickedPoints, map]);

  useEffect(() => {
    calculateArea();
  }, [areaPolygon, unitOption]);

  useEffect(() => {
    if (map) {
      if (highlightedGroup) {
        const group = femaleRowGroupings.find(
          (group) => group.group_id === highlightedGroup
        );

        if (group) {
          let { coordinate } = group;
          coordinate = coordinate.reverse();
          map.flyTo({
            center: coordinate,
            essential: true,
            zoom: 28,
          });
        }
      }

      addUnclusteredPointLayer(map);
    }
  }, [highlightedGroup, map]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isActive) {
        // Deactivate area measurement
        toggleActive();

        // Clear clicked points and area polygon
        setClickedPoints({
          type: 'FeatureCollection',
          features: [],
        });
        areaPolygonRef.current = []; // Reset the polygon
        setAreaPolygon([]); // Clear area polygon points
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isActive, toggleActive,]);

  // Trigger a layer reset whenever the isActive changes
  useEffect(() => {
    if (map) {
      addUnclusteredPointLayer(map);
      addClusteredPointLayer(map);
    }
  }, [isActive, tasselOption, map]);


  useEffect(() => {
    isActiveRef.current = isActive;
  }, [isActive]);

  // In the useEffect for drawing the area polygon and lines
  // Inside the useEffect for drawing the area polygon and lines
  useEffect(() => {
    if (map && isActive) {
      // Draw lines to the cursor from the last point
      if (areaPolygon.length > 0) {
        const lineCoordinates = [
          ...areaPolygon,
          [map.getCenter().lng, map.getCenter().lat], // Line to cursor
        ];

        if (!map.getLayer('area-line')) {
          map.addLayer({
            id: 'area-line',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'LineString',
                      coordinates: lineCoordinates,
                    },
                  },
                ],
              },
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#888',
              'line-width': 0,
            },
          });
        } else {
          const lineSource = map.getSource('area-line');
          if (lineSource) {
            lineSource.setData({
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: lineCoordinates,
                  },
                },
              ],
            });
          }
        }
      }

      // Draw the area polygon if there are enough points
      if (areaPolygon.length > 2) {
        if (!map.getLayer('area-polygon')) {
          map.addLayer({
            id: 'area-polygon',
            type: 'fill',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates: [areaPolygon.concat([areaPolygon[0]])], // Close the polygon
                    },
                  },
                ],
              },
            },
            layout: {},
            paint: {
              'fill-color': 'rgba(255, 255, 0, 0.5)', // Transparent yellow
              'fill-opacity': 0.5,
            },
          });
        } else {
          const polygonSource = map.getSource('area-polygon');
          if (polygonSource) {
            polygonSource.setData({
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [areaPolygon.concat([areaPolygon[0]])], // Close the polygon
                  },
                },
              ],
            });
          }
        }
      } else {
        // Remove the polygon and line if there are not enough points
        if (map.getLayer('area-polygon')) {
          map.removeLayer('area-polygon');
          map.removeSource('area-polygon');
        }
        if (map.getLayer('area-line')) {
          map.removeLayer('area-line');
          map.removeSource('area-line');
        }
      }
    } else {
      // Clean up when area measurement is not active
      if (map) {
        if (map.getLayer('area-polygon')) {
          map.removeLayer('area-polygon');
          map.removeSource('area-polygon');
        }
        if (map.getLayer('area-line')) {
          map.removeLayer('area-line');
          map.removeSource('area-line');
        }
      }
    }
  }, [isActive, areaPolygon, map]);


  return (
    <div className="map-container" ref={mapContainer}>
      {isActive && (
        <div className="area-display">
          Area: {area.toFixed(2)} {unitOption === 'Square kilometer' ? 'km²' : unitOption === 'Hectare' ? 'ha' : 'm²'}
        </div>
      )}
    </div>
  );
};

export default Map;