// src/index.js
import './bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import './index.css';
import { ShowTypeProvider } from './context/ShowTypeContext';
import { HighlightedGroupProvider } from './context/HighlightedGroupContext';
import { AreaMeasurementProvider } from './context/AreaMeasurementContext'; // Import the new context

ReactDOM.render(
    <HighlightedGroupProvider>
      <ShowTypeProvider>
        <ThemeProvider>
          <AreaMeasurementProvider> {/* Wrap the App with AreaMeasurementProvider */}
            <App />
          </AreaMeasurementProvider>
        </ThemeProvider>
      </ShowTypeProvider>
    </HighlightedGroupProvider>,
  document.getElementById('root')
);
