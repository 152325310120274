// src/App.js
import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from './context/ThemeContext';
import Map from './components/Map';
import Menu from './components/Menu';
import Toolbar from './components/Toolbar'; // Import the Toolbar component
import Login from './components/Login';
import './App.css';
import { supabase } from './supabaseClient';

const App = () => {
  const [styleURL, setStyleURL] = useState('cm1v3fab301ce01qp6azpgrv2');
  const { theme, toggleTheme, isDarkMode, toggleDarkMode } = useContext(ThemeContext);

  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });
  }, []);

  /*
        <button onClick={toggleDarkMode} className="btn btn-secondary theme-toggle-button">
          {isDarkMode ? '🌞' : '🌜'}
        </button>*/

  return user ? <div className={`app ${styleURL ? '' : 'no-map'}`} data-bs-theme="dark">
    <div className="theme-toggle-container">
    </div>
    {styleURL ? <Toolbar /> : null}
    {styleURL ? <Map styleURL={styleURL} /> : null}
  </div> : <Login />;


};

export default App;
