// src/context/ShowTypeContext.js

import React, { createContext, useContext, useState } from 'react';

const ShowTypeContext = createContext();

export const ShowTypeProvider = ({ children }) => {
  const [showMale, setShowMale] = useState(true);
  const [showFemale, setShowFemale] = useState(true);

  return (
    <ShowTypeContext.Provider value={{ showMale, setShowMale, showFemale, setShowFemale }}>
      {children}
    </ShowTypeContext.Provider>
  );
};

export const useShowType = () => {
  return useContext(ShowTypeContext);
};
