import React, { createContext, useContext, useState } from 'react';

// Create the context
const AreaMeasurementContext = createContext();

// Create a provider component
export const AreaMeasurementProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [tasselOption, setTasselOption] = useState('Show tassels');
  const [unitOption, setUnitOption] = useState('Square meter');

  const toggleActive = () => setIsActive((prev) => !prev);

  return (
    <AreaMeasurementContext.Provider
      value={{
        isActive,
        toggleActive,
        tasselOption,
        setTasselOption,
        unitOption,
        setUnitOption,
      }}
    >
      {children}
    </AreaMeasurementContext.Provider>
  );
};

// Create a custom hook to use the context
export const useAreaMeasurement = () => {
  return useContext(AreaMeasurementContext);
};
