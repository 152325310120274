// src/context/HighlightedGroupContext.js

import React, { createContext, useContext, useState } from 'react';

const HighlightedGrouptContext = createContext();

export const HighlightedGroupProvider = ({ children }) => {
  const [highlightedGroup, setHighlightedGroup] = useState(0);

  return (
    <HighlightedGrouptContext.Provider value={{ highlightedGroup, setHighlightedGroup }}>
      {children}
    </HighlightedGrouptContext.Provider>
  );
};

export const useHighlightedGroup = () => {
  return useContext(HighlightedGrouptContext);
};
