import React, { useState } from 'react';
import './RatiosDialog.css';
import femaleRowGroupings from '../points/female_row_groupings.json'; // Directly import the JSON file
import { useHighlightedGroup } from '../context/HighlightedGroupContext'; // Import the context

const RatiosDialog = ({ onClose }) => {
  const { setHighlightedGroup } = useHighlightedGroup(); // Access the context
  const [selectedGroupId, setSelectedGroupId] = useState(null); // State to manage selected group
  const [sortConfig, setSortConfig] = useState({ key: 'group_id', direction: 'ascending' }); // State for sorting

  // Handle the sorting logic
  const sortedGroups = [...femaleRowGroupings].sort((a, b) => {
    if (sortConfig.key === 'workers') {
      const workerA = a.workers.map(worker => worker.name).join(', ');
      const workerB = b.workers.map(worker => worker.name).join(', ');
      return sortConfig.direction === 'ascending'
        ? workerA.localeCompare(workerB)
        : workerB.localeCompare(workerA);
    }
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleSelectGroup = () => {
    if (selectedGroupId) {
      console.log(selectedGroupId);
      setHighlightedGroup(selectedGroupId); // Update context with the selected group ID
      onClose(); // Close the dialog
    }
  };

  const handleDiscard = () => {
    setSelectedGroupId(0); // Reset selection
    setHighlightedGroup(0);
    onClose(); // Close the dialog
  };

  return (
    <div className="ratios-dialog-overlay">
      <div className="ratios-dialog">
        <div className="dialog-header">
          <h2>Ratios</h2>
          <button onClick={onClose} className="close-button">✖</button>
        </div>
        <div className="dialog-body">
          <table className="ratios-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('group_id')}>
                  Ratio {sortConfig.key === 'group_id' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('tassel_count')}>
                  Tassel Count {sortConfig.key === 'tassel_count' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('workers')}>
                  Workers {sortConfig.key === 'workers' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedGroups.map((group, index) => (
                <tr
                  key={index}
                  className={selectedGroupId === group.group_id ? 'selected' : ''}
                  onClick={() => setSelectedGroupId(group.group_id)} // Set selected group on row click
                >
                  <td>{group.group_id}</td>
                  <td>{group.tassel_count}</td>
                  <td>{group.workers.map(worker => worker.name).join(', ')}</td> {/* Display workers */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="dialog-footer">
          <button 
            className="select-group-button" 
            onClick={handleSelectGroup}
            disabled={selectedGroupId === null} // Disable if no group is selected
          >
            Select Ratio
          </button>
          <button className="discard-button" onClick={handleDiscard}>
            Discard
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatiosDialog;
