// src/components/AreaMeasurementDialog.js
import React from 'react';
import { useAreaMeasurement } from '../context/AreaMeasurementContext'; // Import the context
import './AreaMeasurementDialog.css';

const AreaMeasurementDialog = ({ onClose }) => {
  const {
    tasselOption,
    setTasselOption,
    unitOption,
    setUnitOption,
    toggleActive,
  } = useAreaMeasurement(); // Destructure values from context

  const handleStart = () => {
    toggleActive(); // Toggle the measurement state on start
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="area-measurement-dialog-overlay">
      <div className="area-measurement-dialog">
        <div className="dialog-header">
          <h2>Area Measurement</h2>
          <button onClick={onClose} className="close-button">✖</button>
        </div>
        <div className="dialog-body">
          <div className="checkbox-group">
            <h3>Tassels</h3>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  value="Show tassels"
                  checked={tasselOption === 'Show tassels'}
                  onChange={(e) => setTasselOption(e.target.value)}
                />
                Show tassels
              </label>
              <label>
                <input
                  type="radio"
                  value="Show blurred tassels"
                  checked={tasselOption === 'Show blurred tassels'}
                  onChange={(e) => setTasselOption(e.target.value)}
                />
                Show blurred tassels
              </label>
              <label>
                <input
                  type="radio"
                  value="Don't show tassels"
                  checked={tasselOption === "Don't show tassels"}
                  onChange={(e) => setTasselOption(e.target.value)}
                />
                Don't show tassels
              </label>
            </div>
          </div>

          <div className="checkbox-group">
            <h3>Unit of Measure</h3>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  value="Square meter"
                  checked={unitOption === 'Square meter'}
                  onChange={(e) => setUnitOption(e.target.value)}
                />
                Square meter
              </label>
              <label>
                <input
                  type="radio"
                  value="Square kilometer"
                  checked={unitOption === 'Square kilometer'}
                  onChange={(e) => setUnitOption(e.target.value)}
                />
                Square kilometer
              </label>
              <label>
                <input
                  type="radio"
                  value="Hectare"
                  checked={unitOption === 'Hectare'}
                  onChange={(e) => setUnitOption(e.target.value)}
                />
                Hectare
              </label>
            </div>
          </div>

          <p className="warning-message">To end area measurement press Esc.</p>
        </div>
        <div className="dialog-footer">
          <button className="start-button" onClick={handleStart}>
            Start
          </button>
          <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AreaMeasurementDialog;
