// src/ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark');
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme') || 'dark';
    setTheme('dark');
    document.body.classList.toggle('bg-dark', currentTheme === 'dark');
    document.body.classList.toggle('text-white', currentTheme === 'dark');
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.classList.toggle('bg-dark');
    document.body.classList.toggle('text-white');
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    document.querySelectorAll('*').forEach((element) => {
      element.classList.toggle('bg-dark');
      element.classList.toggle('text-white');
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
