// src/components/Toolbar.js
import React, { useState } from 'react';
import { useShowType } from '../context/ShowTypeContext';
import { useHighlightedGroup } from '../context/HighlightedGroupContext';
import RatiosDialog from './RatiosDialog';
import AreaMeasurementDialog from './AreaMeasurementDialog'; // Import the new dialog
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Toolbar.css';

const Toolbar = () => {
  const { showMale, setShowMale, showFemale, setShowFemale } = useShowType();
  const [isToolbarOpen, setIsToolbarOpen] = useState(true);
  const { highlightedGroup, setHighlightedGroup } = useHighlightedGroup();
  const [isRatiosDialogOpen, setIsRatiosDialogOpen] = useState(false);
  const [isAreaMeasurementDialogOpen, setIsAreaMeasurementDialogOpen] = useState(false);

  const toggleToolbar = () => {
    setIsToolbarOpen((prev) => !prev);
  };

  const toggleShowMale = () => {
    setShowMale((prev) => !prev);
  };

  const toggleShowFemale = () => {
    setShowFemale((prev) => !prev);
  };

  const openRatiosDialog = () => {
    setIsRatiosDialogOpen(true);
  };

  const closeRatiosDialog = () => {
    setIsRatiosDialogOpen(false);
  };

  const openAreaMeasurementDialog = () => {
    setIsAreaMeasurementDialogOpen(true);
  };

  const closeAreaMeasurementDialog = () => {
    setIsAreaMeasurementDialogOpen(false);
  };

  return (
    <div className="toolbar-container">
      <button className="toolbar-toggle-button" onClick={toggleToolbar}>
        <i className={`fas fa-toolbox ${isToolbarOpen ? 'open' : ''}`}></i>
      </button>

      {isToolbarOpen && (
        <div className="toolbar card bg-dark text-white">
          <div className="card-body">
            <div className="toggle-buttons">
              <div className="toggle-container">
                <span>Show male</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showMale}
                    onChange={toggleShowMale}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <div className="toggle-container">
                <span>Show female</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showFemale}
                    onChange={toggleShowFemale}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            <button className="ratios-button" onClick={openRatiosDialog}>
              Show Ratios
            </button>
            <span className="custom-tools-span">This is where your custom tools will live.</span>
          </div>
        </div>
      )}

      {isRatiosDialogOpen && <RatiosDialog onClose={closeRatiosDialog} />}
      {isAreaMeasurementDialogOpen && <AreaMeasurementDialog onClose={closeAreaMeasurementDialog} />}
    </div>
  );
};

export default Toolbar;
